import { useTranslation } from 'react-i18next';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFilterText from 'Components/Table/filters/TableFilterText';
import TableCellSortable from 'Components/Table/TableCellSortable';
import {
  ChainFragment,
  Claim,
  CustomerSortableFields,
  PointOfSellOptionFragment,
  UserRoleOptionFragment,
  useChainsQuery,
} from 'Apollo/graphql';
import { useSearchOptionsPos } from 'Utils/options/useSearchOptionsPos';
import {
  areOptionsEqualById,
  getOptionFieldId,
  renderOptionById,
} from 'Utils/helpers';
import TableFilterAutocomplete from 'Components/Table/filters/TableFilterAutocomplete';
import { useAuthUser } from 'Tools/auth';
import {
  IsAcceptedOption,
  useOptionsIsAccepted,
} from 'Utils/options/useOptionsAccepted';
import {
  UserRolesOption,
  useOptionsUserRoles,
} from 'Utils/options/useOptionsUserRoles';

const COL_WIDTHS = [
  { width: '150px' },
  { width: '140px' },
  { width: '120px' },
  { width: '150px' },
  { width: '120px' },
  { width: '120px' },
  { width: '220px' },
  { width: '150px' },
  { width: '150px' },
  { width: '150px' },
  { width: '150px' },
  { width: '150px' },
  { width: '45px' },
];

const RegistrationsTableHeader = (): JSX.Element => {
  const { t } = useTranslation('registrationsAdmin');
  const chainOptions = useChainsQuery();
  const posOptions = useSearchOptionsPos();
  const roleOptions = useOptionsUserRoles();
  const isAcceptedOptions = useOptionsIsAccepted();

  const authUser = useAuthUser();
  const showChainIDs =
    authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_VIEW_CHAIN_IDS;
  const showSensitiveData = authUser?.claimsSet.has(Claim.ReadSensitiveData);
  const showPulzeOn = authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON;

  return (
    <TableHead>
      <TableRow>
        {showChainIDs && (
          <TableCellSortable
            style={COL_WIDTHS[0]}
            name={CustomerSortableFields.ChainId}
          >
            {t('registrations.table.labelChainId')}
          </TableCellSortable>
        )}
        <TableCellSortable
          style={COL_WIDTHS[1]}
          name={CustomerSortableFields.PosId}
        >
          {t('registrations.table.labelPosId')}
        </TableCellSortable>
        <TableCellSortable
          style={COL_WIDTHS[2]}
          name={CustomerSortableFields.AuthorId}
        >
          {t('registrations.table.labelAuthorId')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[6]}>
          {t('registrations.table.labelAuthorRole')}
        </TableCell>
        <TableCellSortable
          style={COL_WIDTHS[3]}
          name={CustomerSortableFields.CreatedAt}
        >
          {t('registrations.table.labelCreatedAt')}
        </TableCellSortable>
        {showSensitiveData && (
          <TableCellSortable
            style={COL_WIDTHS[4]}
            name={CustomerSortableFields.FirstName}
          >
            {t('registrations.table.labelFirstName')}
          </TableCellSortable>
        )}
        {showSensitiveData && (
          <TableCellSortable
            style={COL_WIDTHS[5]}
            name={CustomerSortableFields.LastName}
          >
            {t('registrations.table.labelLastName')}
          </TableCellSortable>
        )}
        {showSensitiveData && (
          <TableCellSortable
            style={COL_WIDTHS[6]}
            name={CustomerSortableFields.Email}
          >
            {t('registrations.table.labelEmail')}
          </TableCellSortable>
        )}
        {showSensitiveData && (
          <TableCell style={COL_WIDTHS[7]}>
            {t('registrations.table.labelPhoneNumber')}
          </TableCell>
        )}
        <TableCellSortable
          style={COL_WIDTHS[9]}
          name={CustomerSortableFields.AcceptedTermsAndConditions}
        >
          {t('registrations.table.labelAcceptedTermsAndConditions')}
        </TableCellSortable>
        {showPulzeOn && (
          <TableCellSortable
            style={COL_WIDTHS[10]}
            name={CustomerSortableFields.AcceptedPulzeOn}
          >
            {t('registrations.table.labelAcceptedPulzeOn')}
          </TableCellSortable>
        )}
        <TableCellSortable
          style={COL_WIDTHS[11]}
          name={CustomerSortableFields.AcceptedPulzeCare}
        >
          {t('registrations.table.labelAcceptedPulzeCare')}
        </TableCellSortable>
        <TableCell style={COL_WIDTHS[12]} />
      </TableRow>
      <TableRow>
        {showChainIDs && (
          <TableCell>
            <TableFilterAutocomplete<ChainFragment>
              name="chain"
              options={chainOptions.data?.chains || []}
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
            />
          </TableCell>
        )}
        <TableCell>
          <TableFilterAutocomplete<PointOfSellOptionFragment>
            name="pos"
            options={posOptions.options}
            getOptionLabel={posOptions.getOptionLabel}
            isOptionEqualToValue={posOptions.isOptionEqualToValue}
            renderOption={renderOptionById(posOptions.getOptionLabel)}
            filterOptions={posOptions.filterOptions}
            loading={posOptions.loading}
            onInputChange={posOptions.onInputChange}
            noOptionsText={posOptions.noOptionsText}
          />
        </TableCell>
        <TableCell>
          <TableFilterText name="author" />
        </TableCell>
        <TableCell>
          <TableFilterAutocomplete<UserRolesOption>
            name="roleCode"
            options={roleOptions.options}
            getOptionLabel={roleOptions.getOptionLabel}
            isOptionEqualToValue={roleOptions.isOptionEqualToValue}
            renderOption={renderOptionById(roleOptions.getOptionLabel)}
            loading={roleOptions.loading}
          />
        </TableCell>
        <TableCell />
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="firstName" />
          </TableCell>
        )}
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="lastName" />
          </TableCell>
        )}
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="email" />
          </TableCell>
        )}
        {showSensitiveData && (
          <TableCell>
            <TableFilterText name="phoneNumber" />
          </TableCell>
        )}
        <TableCell>
          <TableFilterAutocomplete<IsAcceptedOption>
            name="acceptedTermsAndConditions"
            getOptionLabel={isAcceptedOptions.getOptionLabel}
            isOptionEqualToValue={isAcceptedOptions.isOptionEqualToValue}
            renderOption={renderOptionById(isAcceptedOptions.getOptionLabel)}
            options={isAcceptedOptions.options}
          />
        </TableCell>
        {showPulzeOn && (
          <TableCell>
            <TableFilterAutocomplete<IsAcceptedOption>
              name="acceptedPulzeOn"
              getOptionLabel={isAcceptedOptions.getOptionLabel}
              isOptionEqualToValue={isAcceptedOptions.isOptionEqualToValue}
              renderOption={renderOptionById(isAcceptedOptions.getOptionLabel)}
              options={isAcceptedOptions.options}
            />
          </TableCell>
        )}
        <TableCell>
          <TableFilterAutocomplete<IsAcceptedOption>
            name="acceptedPulzeCare"
            getOptionLabel={isAcceptedOptions.getOptionLabel}
            isOptionEqualToValue={isAcceptedOptions.isOptionEqualToValue}
            renderOption={renderOptionById(isAcceptedOptions.getOptionLabel)}
            options={isAcceptedOptions.options}
          />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default RegistrationsTableHeader;
