import { yupResolver, } from '@hookform/resolvers/yup';
import { CustomerVerificationMethod, useCheckIfPhoneIsInUseMutation, useCurrentMarketQuery, useGetVerificationMethodsQuery, useStartCustomerVerificationMutation, } from 'Apollo/graphql';
import { CustomerRegistrationForm, RegistrationStore, useRegistrationStore, } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { useEffect, useMemo, useRef, } from 'react';
import { FormProvider, useForm, } from 'react-hook-form';
import { useAuthUser, } from 'Tools/auth';
import shallow from 'zustand/shallow';
import { USER_VERIFICATION_OPTIONS, } from 'Utils/options/useOptionsUserVerification';
import { removeWhiteSpaces, } from 'Utils/helpers';
import { throttle, } from 'lodash';
import type { FormConfig, } from './types';
import { formValidationSchema, } from './utils';
import View from './View';
import { mapRegisterVariables, } from '../PhaseInformationVerification/useMakePurchase';
import { findDeviceCodeInLists, } from '../PhaseInformationVerification/utils';

const selectStoreData = (s: RegistrationStore) => ({
  registrationEmail: s.registrationEmail,
  customerRegistration: s.customerRegistration,
  verification: s.verification,
  goBackToPhase: s.goBackToPhase,
  completeCustomerRegistrationPhase: s.completeCustomerRegistrationPhase,
  setCustomerRegistration: s.setCustomerRegistration,
  customer: s.customer,
  deviceCodeRequiredList: s.deviceCodeRequiredList,
  deviceCodeRequiredPromotionsList: s.deviceCodeRequiredPromotionsList,
});

const PhaseRegisterCustomer = (): JSX.Element => {
  const {
    registrationEmail,
    customerRegistration,
    verification,
    goBackToPhase,
    completeCustomerRegistrationPhase,
    setCustomerRegistration,
    customer,
    deviceCodeRequiredList,
    deviceCodeRequiredPromotionsList,
  } = useRegistrationStore(selectStoreData, shallow);

  const authUser = useAuthUser();
  const currentMarket = useCurrentMarketQuery();
  const refVerification = useRef<RegistrationStore['verification']>(verification);
  const [checkIfPhoneIsInUse, phoneIsInUseResult,] = useCheckIfPhoneIsInUseMutation();
  const verificationMethods = useGetVerificationMethodsQuery().data?.getVerificationMethods.verificationMethods;

  const formConfig = useMemo<FormConfig>(
    () => ({
      enableTitle: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_GENDER_TITLE_INPUT,
      verificationMethods,
      enablePulzeOn: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PULZE_ON,
      enablePhoneDuplicityVerification: !!authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PHONE_NUMBER_DUPLICITY_VERIFICATION,
      currentMarket: currentMarket.data?.currentMarket,
    }),
    [authUser, currentMarket, verificationMethods,],
  );
  const methods = useForm<CustomerRegistrationForm>({
    defaultValues: {
      ...customerRegistration,
      ...((formConfig.verificationMethods || []).length > 1 && { verificationMethod: USER_VERIFICATION_OPTIONS[0], }),
      // only SMS verification if feature enabled
    },
    resolver: yupResolver(formValidationSchema(formConfig, phoneIsInUseResult.data?.checkIfPhoneIsInUse?.value)),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });
  useEffect(() => {
    if ((formConfig.verificationMethods || []).length > 1) {
      methods.setValue('verificationMethod', USER_VERIFICATION_OPTIONS[0]);
    }
  }, [verificationMethods,]);

  useEffect(() => {
    const throttledCheckPhone = throttle((phone: string, phonePrefixId: string) => {
      checkIfPhoneIsInUse({ variables: { phoneNumber: phone, phonePrefix: phonePrefixId, }, });
    }, 1000); // Throttle API call to once every second

    const subscription = methods.watch((value, { name, type, }) => {
      if (value.phone && value.phonePrefix?.id && value.phonePrefix?.name) {
        throttledCheckPhone(value.phone, value.phonePrefix.id);
      };
    });

    return () => {
      throttledCheckPhone.cancel();
      subscription.unsubscribe();
    };
  }, [checkIfPhoneIsInUse, methods, methods.watch,]);

  useEffect(() => {
    const values = methods.getValues();
    if (values.phone && values.phonePrefix?.id && !phoneIsInUseResult.called) {
      checkIfPhoneIsInUse({ variables: { phoneNumber: values.phone, phonePrefix: values.phonePrefix?.id, }, });
    }
  }, []);

  const [startVerificationMutation, startVerificationResult,] =
    useStartCustomerVerificationMutation({
      fetchPolicy: 'no-cache',
      onCompleted: (result) => {
        if (
          refVerification.current.method !== CustomerVerificationMethod.Email ||
          (refVerification.current.method ===
            CustomerVerificationMethod.Email &&
            import.meta.env.VITE_APP_AWAIT_EMAIL_VERIFICATION !== 'false')
        ) {
          completeCustomerRegistrationPhase(
            methods.getValues(),
            {
              ...refVerification.current,
              shouldVerify: refVerification.current.shouldVerify,
              isVerified: false,
            },
            result.startCustomerVerification,
          );
        } else {
          completeCustomerRegistrationPhase(
            methods.getValues(),
            {
              ...refVerification.current,
              shouldVerify: refVerification.current.shouldVerify,
              isVerified: false,
            },
            {
              __typename: 'CustomerVerificationStartResultSkipVerification',
              skipVerification: true,
            },
          );
        }
      },
    });

  const handleSubmit = methods.handleSubmit(async (values) => {
    const { verificationMethod, phonePrefix, phone, } = values;
    const verificationValue = verificationMethod
      ? verificationMethod.id === CustomerVerificationMethod.Email
        ? registrationEmail
        : `${phonePrefix?.id || ''}${phone}`
      : null;

    const isVerified = !((formConfig.verificationMethods || []).length > 0)
      || (verification.isVerified
        && values.verificationMethod
        && values.verificationMethod.id === verification.method
        && verificationValue === verification.value
      );

    refVerification.current = {
      ...verification,
      method: values.verificationMethod?.id || CustomerVerificationMethod.Email,
      value: verificationValue,
    };

    if (isVerified) {
      completeCustomerRegistrationPhase(
        values,
        {
          isVerified,
          shouldVerify: [formConfig.verificationMethods || [],].length > 0,
          value: verificationValue,
          method: values.verificationMethod?.id || null,
        },
        { __typename: 'CustomerVerificationStartResultSkipVerification', skipVerification: true, },
      );
    } else {
      const firstDeviceCodeFound = findDeviceCodeInLists(deviceCodeRequiredList, deviceCodeRequiredPromotionsList);
      const method = values.verificationMethod?.id || (verificationMethods ? verificationMethods[0] : null);
      const verificatedValueRaw = method === CustomerVerificationMethod.Email ? registrationEmail : `${phonePrefix?.id || ''}${phone}`;
      const verificatedValue = removeWhiteSpaces(verificatedValueRaw);
      await startVerificationMutation({
        variables: {
          method,
          verificatedValue,
          customerInput: mapRegisterVariables({ authUser, customerRegistration: values, customer, email: registrationEmail, }).customerInput,
          deviceCode: firstDeviceCodeFound,
        },
      });
    }
  });
  const handleGoBack = () => {
    setCustomerRegistration(methods.getValues());
    goBackToPhase('purchase');
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <View
          formConfig={formConfig}
          registrationEmail={registrationEmail}
          startVerificationResult={startVerificationResult}
          onGoBack={handleGoBack}
        />
      </form>
    </FormProvider>
  );
};

export default PhaseRegisterCustomer;
