import React from 'react';
import { Backdrop, } from '@mui/material';
import styled from '@emotion/styled';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

interface FullscreenImageOverlayProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  open: boolean;
  onClose: () => void;
}

const ImageContainer = styled('div')(() => ({
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  '& > img': {
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}));

const FullscreenImageOverlay: React.FC<FullscreenImageOverlayProps> = ({
  open,
  onClose,
  src,
  alt,
  ...imgProps
}) => {
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={(event) => {
        if (
          !('tagName' in event.target) ||
          ('tagName' in event.target &&
            typeof event.target.tagName === 'string' &&
            event.target.tagName.toLowerCase() !== 'img')
        ) {
          event.stopPropagation();
        }
        onClose();
      }}
    >
      <ImageContainer>
        <img
          src={src}
          alt={alt || 'Fullscreen Image'}
          style={{
            borderRadius: '8px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
            backgroundColor: 'white',
          }}
          {...imgProps}
        />
        <FullscreenExitIcon
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            color: 'black',
          }}
        />
      </ImageContainer>
    </Backdrop>
  );
};

export default FullscreenImageOverlay;
