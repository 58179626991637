import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { AuthUserFragment } from 'Apollo/graphql';
import { RegistrationStore } from 'Modules/RegistrationSeller/store/RegistrationStore';
import { FormError, FormInputText, FormSubmit } from 'Components/Form';
import ErrorAlert from 'Components/Errors/ErrorAlert';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import ButtonWithSquareIcon from 'Components/ButtonWithSquareIcon';
import ArrowRightIcon from 'Utils/svg/ArrowRightIcon';
import Alert from '@mui/material/Alert';
import { ShowFields } from './utils';
import InputPos from './InputPos';
import InputUser from './InputUser';
import PurchasedProducts from './PurchasedProducts';

interface Props {
  error: ApolloError | Error | null;
  authUser: AuthUserFragment;
  showFields: ShowFields;
  customer: RegistrationStore['customer'];
  purchase: RegistrationStore['purchase'];
  promotionCategoryPurchaseList: RegistrationStore['promotionCategoryPurchaseList'];
  goBackToPhase: RegistrationStore['goBackToPhase'];
  onGoBack: () => void;
  resetIndexes: () => void;
  canSubmit: boolean;
}

const View = ({
  canSubmit,
  error,
  authUser,
  showFields,
  customer,
  purchase,
  goBackToPhase,
  onGoBack,
  resetIndexes,
  promotionCategoryPurchaseList,
}: Props): JSX.Element => {
  const { t } = useTranslation('registrationSeller');
  const handleGoToPurchase = () => {
    goBackToPhase('purchase');
    resetIndexes();
  };

  return (
    <>
      <Typography variant="h1" sx={{ mb: 1.5 }}>
        {t('phaseInformationVerification.title')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        {t('phaseInformationVerification.info')}
      </Typography>

      <Box mb={4}>
        <Grid container spacing={3}>
          {!showFields.registerAsBrandAmbassador && (
            <Grid item xs={12}>
              {showFields.pos ? (
                <InputPos />
              ) : (
                <NotEditableTextClassic
                  label={t('phaseInformationVerification.labelPos')}
                >
                  {authUser.pointOfSell?.name || '-'}
                </NotEditableTextClassic>
              )}
            </Grid>
          )}
          {!showFields.registerAsBrandAmbassador && (
            <Grid item xs={12}>
              {showFields.user ? (
                <InputUser />
              ) : (
                <NotEditableTextClassic
                  label={t('phaseInformationVerification.labelUser')}
                >
                  {authUser.username || '-'}
                </NotEditableTextClassic>
              )}
            </Grid>
          )}
          {showFields.registerAsBrandAmbassador && (
            <Grid item xs={12}>
              <FormInputText
                name="username"
                required
                label={t('phaseInformationVerification.labelUsername')}
              />
            </Grid>
          )}
          {customer && (
            <Grid item xs={12}>
              <NotEditableTextClassic
                label={t(
                  'phaseInformationVerification.labelCustomerInformation',
                )}
              >
                <div>{`${customer?.firstName || ''} ${
                  customer?.lastName || ''
                }`}</div>
                {customer?.dateOfBirth && (
                  <div>
                    {t('common:date.fullDate', { date: customer.dateOfBirth })}
                  </div>
                )}
                <div>{customer?.email || ''}</div>
                <div>{`${customer?.phonePrefix || ''} ${
                  customer?.phoneNumber || ''
                }`}</div>
              </NotEditableTextClassic>
            </Grid>
          )}
          <Grid item xs={12}>
            <NotEditableTextClassic
              label={t(
                'phaseInformationVerification.subTitlePurchasedProducts',
              )}
            >
              <PurchasedProducts
                purchase={purchase}
                promotionCategoryPurchaseList={promotionCategoryPurchaseList}
              />
            </NotEditableTextClassic>
          </Grid>
          {!authUser.featureFlagsObject
            .FEATURE_FLAG_ENABLE_GRPC_VERIFICATION && (
            <Grid item xs={12}>
              <ButtonWithSquareIcon
                icon={<ArrowRightIcon />}
                onClick={handleGoToPurchase}
              >
                {t('phaseInformationVerification.btnChangeProducts')}
              </ButtonWithSquareIcon>
            </Grid>
          )}
        </Grid>
      </Box>

      <FormError sx={{ mb: 2 }} />

      <ErrorAlert sx={{ mb: 2 }} error={error} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg="auto">
          <FormSubmit
            color="primary"
            variant="contained"
            size="large"
            sx={{ width: { xs: '100%', lg: 'unset' } }}
            disabled={!canSubmit}
          >
            {t('phaseInformationVerification.btnSubmit')}
          </FormSubmit>
        </Grid>
        {!authUser.featureFlagsObject.FEATURE_FLAG_ENABLE_GRPC_VERIFICATION && (
          <Grid item xs={12} lg="auto">
            <Button
              size="large"
              variant="outlined"
              color="primary"
              sx={{ width: { xs: '100%', lg: 'unset' } }}
              onClick={onGoBack}
            >
              {t('common:actions.back')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default View;
