import {
  CustomerSortInput,
  PaginationInput,
  CustomerFilterInput,
  CustomerAssortmentPurchaseFragment,
  PurchaseSortInput,
  PurchaseFilterInput,
  CustomerPromotionPurchaseFragment,
  CustomerSortableFields,
  OrderBy,
  PurchaseSortableFields,
} from 'Apollo/graphql';
import { PurchasesStore } from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import { RegistrationStore } from 'Modules/RegistrationsAdmin/store/RegistrationStore';

export const mapVariablesRegistration = (
  storeData: RegistrationStore['tableRegistrations'],
): {
  filter?: CustomerFilterInput;
  pagination?: PaginationInput;
  sort?: CustomerSortInput;
} => {
  let userFilter: CustomerFilterInput['author'] = {};
  if (storeData.filter.author) {
    userFilter = {
      ...userFilter,
      username: {
        contains: storeData.filter.author,
      },
    };
  }
  if (storeData.filter.roleCode) {
    userFilter = {
      ...userFilter,
      roleCode: {
        equals: storeData.filter.roleCode.id,
      },
    };
  }

  if (Object.keys(userFilter).length === 0) {
    userFilter = undefined;
  }

  return {
    pagination: {
      limit: storeData.data.limit,
      offset: storeData.data.offset,
    },
    filter: {
      chainId: storeData.filter.chain
        ? { equals: storeData.filter.chain.id }
        : undefined,
      posId: storeData.filter.pos
        ? { equals: storeData.filter.pos.id }
        : undefined,
      author: userFilter,
      firstName: storeData.filter.firstName
        ? { contains: storeData.filter.firstName }
        : undefined,
      lastName: storeData.filter.lastName
        ? { contains: storeData.filter.lastName }
        : undefined,
      phoneNumberWithPrefix: storeData.filter.phoneNumber
        ? { contains: storeData.filter.phoneNumber }
        : undefined,
      email: storeData.filter.email
        ? { contains: storeData.filter.email }
        : undefined,
      acceptedTermsAndConditions:
        storeData.filter.acceptedTermsAndConditions?.value,
      acceptedPulzeOn: storeData.filter.acceptedPulzeOn?.value,
      acceptedPulzeCare: storeData.filter.acceptedPulzeCare?.value,
    },
    sort:
      storeData.data.sortField && storeData.data.sortOrder
        ? {
            field: storeData.data.sortField,
            order: storeData.data.sortOrder,
          }
        : { field: CustomerSortableFields.CreatedAt, order: OrderBy.Desc },
  };
};

export const mapVariablesPurchases = (
  storeData: PurchasesStore['tablePurchases'],
): {
  filter?: PurchaseFilterInput;
  pagination?: PaginationInput;
  sort?: PurchaseSortInput;
} => ({
  pagination: {
    limit: storeData.data.limit,
    offset: storeData.data.offset,
  },
  filter: {
    chainId: storeData.filter.chain?.id
      ? { equals: storeData.filter.chain.id }
      : undefined,
    posId: storeData.filter.pos?.id
      ? { equals: storeData.filter.pos.id }
      : undefined,
    author: storeData.filter.author
      ? { username: { contains: storeData.filter.author } }
      : undefined,
    customer: storeData.filter.email
      ? { email: { contains: storeData.filter.email } }
      : undefined,
    deviceCode: storeData.filter.deviceCode
      ? { contains: storeData.filter.deviceCode }
      : undefined,
    productName: storeData.filter.assortmentName
      ? { contains: storeData.filter.assortmentName }
      : undefined,
    productId: storeData.filter.assortmentId
      ? { equals: storeData.filter.assortmentId }
      : undefined,
    promotionName: storeData.filter.promotionName
      ? { contains: storeData.filter.promotionName }
      : undefined,
    promotionId: storeData.filter.promotionId
      ? { equals: storeData.filter.promotionId }
      : undefined,
  },
  sort:
    storeData.data.sortField && storeData.data.sortOrder
      ? {
          field: storeData.data.sortField,
          order: storeData.data.sortOrder,
        }
      : { field: PurchaseSortableFields.CreatedAt, order: OrderBy.Desc },
});

export const countPurchasedProducts = (
  purchasedProducts:
    | CustomerAssortmentPurchaseFragment[]
    | CustomerPromotionPurchaseFragment[],
) => [...purchasedProducts].reduce((prev, current) => prev + current.count, 0);
